import { mapGetters } from "vuex";
import { translateCategory } from "@/utils";

export default {
	name: "Tag",
	props: {
		tag: Object,
	},
	components: {},
	data: () => ({}),
	computed: {
		...mapGetters("machine", {
			categories: "getCategories",
		}),
		categoryName() {
			if (this.tag && this.tag.name) {
				return translateCategory(this.tag.name);
			}

			return this.tag.name;
		},
	},
	methods: {
		styling(category_id) {
			return `color: ${this.categories.find((c) => c.category_id == category_id).color}; background: ${
				this.categories.find((c) => c.category_id == category_id).background_color
			};`;
		},
	},
};
