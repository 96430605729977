const translateCategory = (category) => {
	switch (category) {
		case "Strawberry":
			return "Aardbeien";
		case "Bread":
			return "Brood";
		case "Vegetables":
			return "Groenten";
		case "Fruit and Vegetables":
			return "Groenten en fruit";
		case "Medicines":
			return "Medicijnen";
		case "Baby articles":
			return "Baby artikelen";
		case "Money":
			return "Geld";
		case "Chocolate":
			return "Chocolade";
		case "Eggs":
			return "Eieren";
		case "Cigarettes":
			return "Sigaretten";
		case "Pizza":
			return "Pizza";
		case "Flowers":
			return "Bloemen";
		case "Candy & Snacks":
			return "Snoep & Snacks";
		case "Hot snacks":
			return "Warme snacks";
		case "Fresh meals":
			return "Verse maaltijden";
		case "Others":
			return "Anderen";
		case "Beverages":
			return "Dranken";
		case "Hot beverages":
			return "Warme dranken";
		case "Potatoes":
			return "Aardappelen";
		case "Milk":
			return "Melk";
		case "Ice Cream":
			return "ijs";
		case "Jewelry and clothing":
			return "Juwelen en kledij";
		case "Bike parts":
			return "Fiets onderdelen";
		case "Fishing":
			return "Hengelsport";
		default:
			return category;
	}
};

const translateLocation = (location) => {
	switch (location) {
		case "indoor":
			return "Binnen";
		case "outdoor":
			return "Buiten";
		default:
			return location;
	}
};

const translateOpeningTime = (openingTime) => {
	switch (openingTime) {
		case "day":
			return "Overdag";
		case "night":
			return "Snachts";
		default:
			return openingTime;
	}
};

export { translateCategory, translateLocation, translateOpeningTime };
