<template>
	<!-- Premium card -->
	<router-link
		v-if="machine.is_premium"
		class="c-machine-card"
		:to="{ name: 'Detail', params: { id: machine.vending_machine_id } }"
	>
		<img
			class="c-machine-card__thumbnail"
			:src="machine.imgPath !== undefined ? machine.imgPath : require('@/assets/images/vending_placeholder.png')"
			alt=""
		/>
		<div class="c-machine-card__content">
			<div class="c-machine-card__top">
				<div class="c-machine-card__tags" :class="{ 'u-opacity-60': machine.out_of_use }">
					<Tag :tag="machine.main_category" />
					<Tag v-for="categories in machine.categories.slice(0, 2)" :key="categories.id" :tag="categories" />
				</div>
				<div class="c-machine-card__favourite">
					<img :src="determineFavIcon" alt="" @click.prevent="toggleFavourite()" />
				</div>
			</div>
			<div class="c-machine-card__info" :class="{ 'u-opacity-60': machine.out_of_use }">
				<div>
					<p class="c-machine-card__name">
						{{ machine.name }}
					</p>
					<p class="c-machine-card__address">
						{{ machine.address.street }}
					</p>
				</div>
				<div>
					<span class="c-machine-card__distance"> {{ machine.distance ? mToKm(machine.distance) : "" }}</span>
				</div>
			</div>
			<hr class="c-hr--dark" />
			<div v-if="!machine.out_of_use" class="c-machine-card__hours">
				<img src="@/assets/images/icons/clock.svg" alt="opening hours" />
				{{ machine.openingTimes }}
			</div>
			<div v-if="machine.out_of_use" class="c-machine-card__outofuse" />
		</div>
	</router-link>
	<!-- Non-premium card -->
	<router-link
		v-else
		class="c-machine-card c-machine-card--free"
		:to="{ name: 'Detail', params: { id: machine.vending_machine_id } }"
	>
		<div class="c-machine-card__content">
			<div class="c-machine-card__top" :class="{ 'u-opacity-60': machine.out_of_use }">
				<div class="c-machine-card__tags">
					<Tag :tag="machine.main_category" />
					<Tag v-for="tag in machine.categories.slice(0, 2)" :key="tag.id" :tag="tag" />
				</div>
				<div class="c-machine-card__right">
					<span class="c-machine-card__distance"> {{ machine.distance ? mToKm(machine.distance) : "" }}</span>
					<div v-if="false" class="c-machine-card__favourite">
						<img :src="determineFavIcon" alt="" @click.prevent="toggleFavourite()" />
					</div>
				</div>
			</div>
			<div class="c-machine-card__info">
				<p class="c-machine-card__address" :class="{ 'u-opacity-60': machine.out_of_use }">
					{{ machine.address.street }} {{ machine.address.number }}, {{ machine.address.city }}
				</p>
				<div v-if="!machine.out_of_use" class="c-machine-card__hours">
					<img src="@/assets/images/icons/clock.svg" alt="opening hours" />
					<span class="u-tt-c">{{ translateOpeningTime(machine.opening_time.name) }}</span>
				</div>
				<div v-if="machine.out_of_use" class="c-machine-card__outofuse">
					<img src="@/assets/images/icons/out_of_use.svg" alt="opening hours" />
					Buiten gebruik
				</div>
			</div>
		</div>
	</router-link>
</template>

<script src="./MachineCard.controller.js"></script>
<style lang="scss" src="./MachineCard.styles.scss"></style>
